



























































import { Component, Vue } from "vue-property-decorator";
import { getCurrentDoctorInfo, personal } from "@/apis";
import IllData from "@/views/OnlineConsultRoom/PatientDetail/module/IllData.vue";

@Component({
  components: {
    IllData,
  },
})
@Component
export default class Home extends Vue {
  activeName = "first";
  form = {
    doctor_id: 20081, // 医生id
    inquiry_check: 1, // 图文问诊是否需要审核 0不需要 1需要
    video_check: 1, // 视频问诊是否需要审核 0不需要 1需要
    office: "", // 科室
    position: "", // 职位
    skill: "", // 擅长
    inquiry_price: 0, // 问诊价格
    inquiry_open: 1, // 是否开通问诊 1开通，0关闭
    inquiry_public: 1, // 是否开放远程 1开放  0未开方放
    remotectrl_price: 0, // 远程程控价格
    remotectrl_open: 1, // 是否开通远程 1开通，0关闭
    remotectrl_public: 1, // 是否开放远程 1开放  0未开方放
    video_price: 0, // 视频价格
    video_open: 1, // 是否开通视频 1开通，0关闭
    video_public: 1, // 是否开通视频 1开通，0关闭
    doctor_name: "", // 医生姓名
  };
  created() {
    this.getDoctorInfo();
  }

  // mounted() {
  //   personal({}).then(res=>{
  //     console.log(res,1111);

  //   })
  // }
  // 获取当前登陆的医生信息

  async getDoctorInfo() {
    try {
      const result = await getCurrentDoctorInfo();
      // console.log("requestList", result);
      // console.log(result,'12345677888888');

      if (result.success) {
        this.form = result.data;
        //this.$store.commit('verify/CHANGE_ISWITHINVERIFY', true)
        sessionStorage.setItem("doctorId", result.data.doctor_id);
      } else {
        // if result.error_level === 1 {
        //
        // }
      }
    } catch (error) {
      console.log(error);
    }
  }
}
